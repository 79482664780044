/* eslint-disable no-console */
import { Loader } from 'components/Loader';
import { GetServerSidePropsContext, GetServerSidePropsResult } from 'next';
import { getServerSession } from 'next-auth/next';
import { ReactElement } from 'react';
import { authOptions } from '../api/auth/[...nextauth].page';

type LoginPageProps = {
  callbackUrl: string;
};

export default function LoginPage() {
  return <Loader />;
}

LoginPage.getLayout = (page: ReactElement) => page;

export async function getServerSideProps(
  context: GetServerSidePropsContext,
): Promise<GetServerSidePropsResult<LoginPageProps>> {
  const session = await getServerSession(context.req, context.res, authOptions);

  const { query } = context;

  const callbackUrl = `${query.callbackUrl ?? '/'}`;

  // If the user is already logged in, redirect.
  // Note: Make sure not to redirect to the same page
  // To avoid an infinite loop!
  if (session) {
    return { redirect: { permanent: false, destination: callbackUrl } };
  }

  return {
    props: { callbackUrl },
  };
}
